import React, { Component } from 'react';
import styles from '../css/homeStyle.module.css';

export class TeamTreeContents extends Component {
    render() {
        return (
            <div className={styles.bodyTop}>
                <div className={styles.bodyTopImg2}>
                </div>
            </div>
        );
    }
}